<template>
    <div class="container">
        <div class="payment-waiting">
            <Loader class="mb-8" />

            <div class="text-center mb-11">
                {{ t("payment.payment_waiting") }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { SetupResponseEvervault3DSData } from "@/types/SetupResponse";
import { usePaymentStore } from "@/store/payment";
import Loader from "@/components/app/Loader.vue";
import { useMessageStore } from "@/store/message";
import { useBasketStore } from "@/store/basket";
import { postCheckout } from "@/services/basket";
import { AxiosResponse, isAxiosError } from "axios";

interface Props {
    data: SetupResponseEvervault3DSData;
}

const props = defineProps<Props>();

const router = useRouter();
const { t } = useI18n();

const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const messageStore = useMessageStore();

const evervault = new Evervault("team_0e5197190ac1", "app_01a23c15ee0d");
const threeDSecure = evervault.ui.threeDSecure(props.data["3ds_id"]);

onMounted(() => {
    threeDSecure.mount();
});

onUnmounted(() => {
    threeDSecure.unmount();
});

threeDSecure.on("success", async () => {
    const form = paymentStore.getActiveForm();

    if (!form || !basketStore.basketId) {
        router.push({ name: "payment" });
        return;
    }

    // 3DS is complete and payment can be finalized
    try {
        await paymentStore.makePayment({
            ...form.values,
            // Add the 3DS txn_id to the payment
            txn_id: props.data.txn_id,
            evervault_3d_secure: true,
        });
    } catch (error) {
        if (isAxiosError(error)) {
            const response = error.response as AxiosResponse;

            messageStore.addMessage(
                response.data.detail ??
                    response.data.message ??
                    t("error.unexpected"),
                {
                    type: "error",
                },
            );
        } else {
            messageStore.addMessage(t("error.unexpected"), {
                type: "error",
            });
        }

        router.push({ name: "payment" });
    }
});

threeDSecure.on("failure", () => {
    messageStore.addMessage(t("error.3ds_failure"), {
        type: "error",
        timeout: 15000,
    });
    router.push({ name: "payment" });
});

threeDSecure.on("error", (error: { code: string; message: string }) => {
    messageStore.addMessage(error.message, { type: "error" });
    router.push({ name: "payment" });
});
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}
.payment-waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 475px;
}
</style>
