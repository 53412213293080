import { ComputedRef, Ref, computed, nextTick, ref, watch } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";
import { PaymentMethod, PaymentMethodDetails } from "@/types/PaymentMethod";
import { UseAsyncStateReturn, useAsyncState, useStorage } from "@vueuse/core";
import { useBasketStore } from "./basket";
import {
    getAllPaymentMethods,
    getPaymentMethod,
} from "@/services/payment-method";
import { handleError } from "@/services/api";
import { localPaymentMethodManager } from "@/services/local-payment-method";
import { useAppStore } from "./app";

export interface PaymentMethodsState {
    items: UseAsyncStateReturn<
        PaymentMethod[],
        [args: { basketId: string | undefined }],
        false
    >;
    searchByName: ComputedRef<(searchTerm: string) => PaymentMethod[]>;
    slicedPaymentMethods: ComputedRef<PaymentMethod[]>;
    selectedPaymentMethod: Ref<PaymentMethod | null>;
    selectedPaymentMethodDetails: Ref<PaymentMethodDetails | null>;
    isPaymentMethodLoading: Ref<boolean>;
    fetchSelectedPaymentMethodDetails: (silent?: boolean) => Promise<void>;
    fetchPaymentMethodDetails: (
        paymentMethod: PaymentMethod,
        silent?: boolean,
    ) => Promise<void>;
    fetchPaymentMethods: () => Promise<PaymentMethod[]>;
    setSelectedPaymentMethodIndex: (index: number) => void;
    getRelevantPaymentMethods: (
        countryCode: string,
        limit?: number,
    ) => Promise<PaymentMethod[]>;
    saveLastUsedPaymentMethod: (ident: string) => void;
}

export const usePaymentMethodStore = defineStore(
    "paymentMethods",
    function (): PaymentMethodsState {
        const basketStore = useBasketStore();
        const appStore = useAppStore();
        const isInitialLoad = ref<boolean>(true);
        const lastSelectedPaymentMethod = useStorage(
            "TbxLastSelectedPaymentMethod",
            "",
        );

        const items = useAsyncState(
            async (args: { basketId: string | undefined }) => {
                if (!args.basketId) {
                    return [];
                }

                const items = await getAllPaymentMethods(args.basketId);

                // If the user has a last selected payment method, move it to the front & set it as the selected method
                if (lastSelectedPaymentMethod.value) {
                    const savedMethodIndex = items.findIndex(
                        (method) =>
                            method.ident === lastSelectedPaymentMethod.value,
                    );

                    if (savedMethodIndex > -1) {
                        items.unshift(items.splice(savedMethodIndex, 1)[0]);
                    }
                }

                isInitialLoad.value = false;

                return items;
            },
            [],
            {
                immediate: false,
                shallow: false,
                resetOnExecute: false,
            },
        );

        const searchByName = computed(() => {
            return (searchTerm: string) =>
                items.state.value?.filter((item) =>
                    item.name
                        .toLocaleLowerCase()
                        .includes(searchTerm.toLocaleLowerCase()),
                );
        });

        const getMethodIndex = (method: PaymentMethod | null) => {
            if (!items.state.value || method === null) return -1;

            return items.state.value.findIndex(
                (curr) => curr.ident === method.ident,
            );
        };

        const selectedPaymentMethod: Ref<PaymentMethod | null> = ref(null);
        const selectedPaymentMethodDetails: Ref<PaymentMethodDetails | null> =
            ref(null);
        const prevSelectedMethod = ref<PaymentMethod | null>(null);
        const isPaymentMethodLoading = ref(false);

        /**
         * Sliced payment methods, only the first 4 payment methods are shown.
         * If the selected payment method is not in the first 4, it will be shown as the last item
         */
        const slicedPaymentMethods = computed(() => {
            if (!items.state.value) return [];

            const firstFourMethods = items.state.value.slice(0, 4);

            if (selectedPaymentMethod.value === null) {
                return firstFourMethods;
            }

            const selectedIndex = getMethodIndex(selectedPaymentMethod.value);
            const prevSelectedMethodIndex = getMethodIndex(
                prevSelectedMethod.value,
            );

            if (selectedIndex === -1) {
                console.warn("Selected payment method not found in the list");
            }

            // Check if the selected payment method is within the first four methods
            if (selectedIndex <= 3) {
                // Check if the previously selected method needs to be shown
                if (
                    prevSelectedMethod.value !== null &&
                    prevSelectedMethodIndex >= 3 &&
                    prevSelectedMethodIndex !== selectedIndex &&
                    selectedIndex !== 3
                ) {
                    return [
                        ...firstFourMethods.slice(0, 3),
                        prevSelectedMethod.value,
                    ];
                } else {
                    return firstFourMethods;
                }
            } else {
                // If the selected payment method is not within the first four, return the first three plus the selected method
                return [
                    ...firstFourMethods.slice(0, 3),
                    selectedPaymentMethod.value,
                ];
            }
        });

        /**
         * The first n payment methods based on the user's previous selection/region.
         * Excludes the currently selected method.
         * @param countryCode The user's country code
         */
        const getRelevantPaymentMethods = async (
            countryCode: string,
            limit?: number,
        ) => {
            await items.execute(0, { basketId: basketStore.basketId });

            // Filter methods by country
            const methods = items.state.value.filter((method) => {
                return (
                    // Ensure the method is available in the user's country
                    (method.countries?.includes(countryCode) ||
                        method.countries?.includes("*")) &&
                    // And the method is not the currently selected
                    method.ident !== selectedPaymentMethod.value?.ident
                );
            });

            // Return the first 8 methods
            if (limit) {
                return methods.slice(0, limit);
            } else {
                return methods;
            }
        };

        async function fetchPaymentMethodDetails(
            paymentMethod: PaymentMethod,
            silent: boolean = false,
        ) {
            if (!basketStore.basketId) {
                return;
            }

            if (!silent) {
                isPaymentMethodLoading.value = true;
            }

            if (
                localPaymentMethodManager.isLocalPaymentMethod(
                    paymentMethod.ident,
                )
            ) {
                // Select the method using the local payment method manager
                const localMethod =
                    await localPaymentMethodManager.selectMethod(
                        paymentMethod.ident,
                        basketStore.basketId,
                    );

                selectedPaymentMethodDetails.value = localMethod.details;
            } else {
                // If test mode is enabled, we hardcode the test method
                const ident = appStore.config?.testMode
                    ? "testmethod"
                    : paymentMethod.ident;

                try {
                    selectedPaymentMethodDetails.value = await getPaymentMethod(
                        basketStore.basketId,
                        ident,
                    );
                } catch (error) {
                    handleError(error);
                }
            }

            if (!silent) {
                // Wait for the next tick to ensure the loading state is updated
                await nextTick();
                isPaymentMethodLoading.value = false;
            }
        }

        async function fetchSelectedPaymentMethodDetails(
            silent: boolean = false,
        ) {
            if (selectedPaymentMethod.value) {
                await fetchPaymentMethodDetails(
                    selectedPaymentMethod.value,
                    silent,
                );
            }
        }

        // Fetch the payment method details when selectedPaymentMethod changes
        watch(selectedPaymentMethod, async (newValue, oldValue) => {
            if (oldValue && getMethodIndex(oldValue) >= 3) {
                prevSelectedMethod.value = oldValue;
            }

            if (newValue) {
                await fetchPaymentMethodDetails(newValue);
            }
        });

        async function fetchPaymentMethods() {
            return items.execute(0, { basketId: basketStore.basketId });
        }

        function setSelectedPaymentMethod(paymentMethod: PaymentMethod) {
            selectedPaymentMethod.value = paymentMethod;
        }

        function setSelectedPaymentMethodIndex(index: number) {
            if (items.state.value) {
                setSelectedPaymentMethod(items.state.value[index]);
            }
        }

        function saveLastUsedPaymentMethod(ident: string) {
            lastSelectedPaymentMethod.value = ident;
        }

        return {
            items,
            searchByName,
            slicedPaymentMethods,
            selectedPaymentMethod,
            selectedPaymentMethodDetails,
            isPaymentMethodLoading,
            fetchSelectedPaymentMethodDetails,
            fetchPaymentMethodDetails,
            fetchPaymentMethods,
            setSelectedPaymentMethodIndex,
            getRelevantPaymentMethods,
            saveLastUsedPaymentMethod,
        };
    },
);

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(usePaymentMethodStore, import.meta.hot),
    );
}
